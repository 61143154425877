<template>
    <body>
        <div v-if="this.isMobile === true" v-html="PluginsoftHistory1" class="background" style="display:flex; justify-content: center; align-items: center;"></div>
        <div v-else v-html="PluginsoftHistory2" class="background" style="display:flex; justify-content: center; align-items: center;"></div>
    </body>
</template>

<script scpoed>
// import axios from 'axios'
// var baseUrl = 'https://218.153.187.125:8080/rest/api/2'
//var baseUrl = 'https://10.0.1.179:8080/rest/api/2'

export default ({
     data() {
     return {
        PluginsoftHistory1 : '<div id="wrap"><div id="header"></div><div id="contents"><h3 style="text-align: center;">연혁</h2><hr><div class="onethird" style="font-size:0.5em; line-height:1.5em;"><p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023.01~</b> SaaS 기반 AI 솔루션 개발<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2022.09~</b> 삼성전자 Foundry Jira To Salesforce 연계 컨설팅<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2022.07~</b> SK ON EMS 개발<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2022.01~</b> 삼성전자 Foundry ITS JIRA 운영<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2021.10~</b> 삼성전자 반도체 OPC 개발<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2021.01~</b> 삼성전자 DT/Confluence JIRA 운영, 삼성전자 Foundry ITS JIRA 운영<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2020.06~</b> 삼성전자 DS JIRA 성능튜닝<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2020.03~</b> 롯데카드 DevOps(Bitbucket, Bamoo, Jira) 운영<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2020.01~</b> 삼성전자 DT JIRA/Confluence 운영, 삼성전자 Foundry ITS JIRA 운영<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2019.10~</b> 삼성전자 CAE Confluence Knox 결재연동 개발 및 SSO 개발<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2019.03~</b> 삼성전자 DS PLM JIRA Plugin 개발, I/F 서버 개발, JIRA 운영<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2019.01~</b> 사업재개, 삼성전자 Foundry ITS JIRA 운영<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2015.08~</b> 회사 설립 및 휴업<br></p></div><div class="onethird"><div class="onethird  last"></div></div><hr  class="hidden" style="visibility: hidden;" /></div>',
        PluginsoftHistory2 : '<div id="wrap"><div id="header"></div><div id="contents"><h2 style="text-align: center;">연혁</h2><hr><div class="onethird" style="font-size:1em; line-height:2.5em;"><p><b>2023.01~</b> SaaS 기반 AI 솔루션 개발<br><b>2022.09~</b> 삼성전자 Foundry Jira To Salesforce 연계 컨설팅<br><b>2022.07~</b> SK ON EMS 개발<br><b>2022.01~</b> 삼성전자 Foundry ITS JIRA 운영, 롯데카드 DevOps(Bitbucket, Bamoo, Jira) 운영<br><b>2021.10~</b> 삼성전자 반도체 OPC 개발<br><b>2021.01~</b> 삼성전자 DT/Confluence JIRA 운영, 삼성전자 Foundry ITS JIRA 운영, 롯데카드 DevOps(Bitbucket, Bamoo, Jira) 운영<br><b>2020.06~</b> 삼성전자 DS JIRA 성능튜닝<br><b>2020.03~</b> 롯데카드 DevOps(Bitbucket, Bamoo, Jira) 운영<br><b>2020.01~</b> 삼성전자 DT JIRA/Confluence 운영, 삼성전자 Foundry ITS JIRA 운영<br><b>2019.10~</b> 삼성전자 CAE Confluence Knox 결재연동 개발 및 SSO 개발<br><b>2019.03~</b> 삼성전자 DS PLM JIRA Plugin 개발, I/F 서버 개발, JIRA 운영<br><b>2019.01~</b> 사업재개, 삼성전자 Foundry ITS JIRA 운영<br><b>2015.08~</b> 회사 설립 및 휴업<br></p></div><div class="onethird"><div class="onethird  last"></div></div><hr  class="hidden" style="visibility: hidden;" /></div>'    ,
        isMobile: false,
        isTablet: false,
        isDesktop: false
    }
   },
    mounted() {
      // 기기의 크기에 따라 브라우저 윈도우의 너비를 기준으로 판단할 수 있습니다.
      this.isMobile = window.innerWidth < 768;
      this.isTablet = window.innerWidth >= 768 && window.innerWidth < 992;
      this.isDesktop = window.innerWidth >= 992;
    },
    computed: {
      changeClass() {
        // 기기의 크기에 따라 클래스를 동적으로 할당합니다.
        return {
          "mobile": this.isMobile,
          "tablet": this.isTablet,
          "desktop": this.isDesktop
        };
      }
    },
    created() {
        // var _app = this;
        // axios.get( baseUrl + '/issue/HOME-7', {headers: {'Accept':'application/json', 'Authorization':'Basic czA2MjdqczpwbHVnaW5zdzEwMDQ='}}, {httpsAgent : { rejectUnauthorized : false}})
        // .then(function (response)  {
        //         _app.$data.PluginsoftHistory = response.data.fields.description.toString()
        // })
    },
})
</script>



<style scoped>
.background {
    height: 100vh;
    overflow: hidden;
    margin: 0;
    background-image: url("../assets/letterpaper.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
body {
    width:100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    align-items: center;
    line-height: 50px;
    vertical-align: middle;
}
</style>