<template>
    <div class="overflow-auto">
        <br>
        <h2>기술자료 게시판</h2>
        <br>
      <b-table style="width: 100%;" :per-page="perPage" :items="items" :fields="fields"
        :current-page="currentPage" id="table"
        @row-clicked="rowClick">
      </b-table>
    <div v-bind:childmessage="number"></div>
    <div>
    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" align="center"></b-pagination>
    <!-- <b-button @click="test">글쓰기</b-button> -->
  </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
var baseUrl = 'https://218.153.187.125:8080/rest/api/2'

export default {
  data(){
    return {
      fields: [
        {key:'Num',label:'번호'}, {key:'displayName',label:'작성자'}, {key:'summary',label:'제목'}, {key:'created',label:'작성일'}
      ],
      Id : '',
      description : '',
      number :'',
      writer : '',
      title : '',
      date : '',
      issues: [],
      items: [],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지당 보여줄 갯수
    }
  },

  computed: {			// computed는 계산 목적으로 사용된다고 보면 됨
    rows() {
      return this.issues.length;
    }
  },

  mounted() {			// mounted는 페이지가 켜질때 실행됨, 페이지가 켜질때 글 리스트들을 db에서 불러옴
    var _app = this;
    var i = 0
    axios.get( baseUrl + '/search?jql=project = HomeBoard &fields=summary,description,created,creator ', {headers: {'Accept':'application/json', 'Authorization':'Basic czA2MjdqczpwbHVnaW5zdzEwMDQ='}}, {httpsAgent : { rejectUnauthorized : false}})
    .then(function (response)  {
      _app.$data.issues = response.data.issues
    })
    .finally(function ()  {
      for (i=0; i < Object.keys(_app.$data.issues).length; i = i + 1){
        _app.$data.title = _app.$data.issues[i].fields.summary
        _app.$data.number = _app.$data.issues[i].key.split('-')[1]
        _app.$data.date = _app.$data.issues[i].fields.created.split('T')[0]
        _app.$data.writer = _app.$data.issues[i].fields.creator.displayName
        _app.$data.Id = _app.$data.issues[i].id
        _app.$data.description = _app.$data.issues[i].fields.description

        _app.$data.items.push({
          Num : _app.$data.number, 
          displayName : _app.$data.writer, 
          summary : _app.$data.title, 
          created : _app.$data.date, 
          rowId : _app.$data.Id,
          desc : _app.$data.description
        });

        _app.$data.number = {};
        _app.$data.writer = {};
        _app.$data.title = {};
        _app.$data.date = {};
        _app.$data.Id = {};
        _app.$data.description = {};

      }
    })
  },
 
  methods: {
    rowClick( item ) {
      this.$router.push({ 
        name: 'BullettinDetail', 
        params: {
          contentId: item.rowId, 
          contentwriter: item.displayName, 
          contentdate: item.created, 
          contentdesc: item.desc,
          contenttitle: item.summary
        }
      });
    }
  },
};
</script>
