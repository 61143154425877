<template>
    <div>
      <br><br><br>
      <div><h1 style="text-align:left;">Pluginsoft 기술 소개 및 자료</h1></div>
      <br>
  <b-card-group columns>
    <b-card v-for="item in jdata" :key="item"
    >
      <b-card-title>{{ item.fields.summary }}</b-card-title>
      <b-card-body>{{ item.fields.description }}</b-card-body>
    </b-card>

  <router-link to="Bullettin">
    <b-card>
      <b-card-title>더보기</b-card-title>
      <b-card-body>
        <img src="../assets/right-arrow.png" width="36px" height="36px">
      </b-card-body>
    </b-card>
    </router-link>
  </b-card-group>
  
</div>
</template>


<script scoped>
import axios from 'axios'

var baseUrl = 'https://218.153.187.125:8080/rest/api/2'

export default ({
   data() {
     return {
        jdata : [],
        imgUrl: [],
      }
   },
  mounted() {
    var _app = this
    var i = 0
      axios.get(baseUrl + '/search?jql=project=HomeBoard&fields=attachment,summary,description',  {headers: {'Accept':'application/json', 'Authorization':'Basic czA2MjdqczpwbHVnaW5zdzEwMDQ='}}, {httpsAgent : { rejectUnauthorized : false}})
      .then(function (response)  {
        for (i = 0; i < response.data.issues.length; i = i + 1) {
          if (i < 7) {
            _app.$data.jdata.push(response.data.issues[i])
            _app.$data.jdata[i].fields.description = _app.textLengthOverCut(response.data.issues[i].fields.description)
            var isImage = response.data.issues[i].fields.attachment[0].filename.split('.')[1]
            if (isImage == 'jpg' || isImage == 'bmp') {
              _app.$data.imgUrl.push(response.data.issues[i].fields.attachment[0].content.toString())
            }
            else {
              _app.$data.imgUrl.push(null)
            }
          }
        }
      })
      .finally(function() {
        /*
        for (i = 0; i < _app.$data.imgUrl.length; i = i + 1) {
          if (_app.$data.imgUrl[i] != null) {
            var issueId = _app.$data.imgUrl[i].split('/')[_app.$data.imgUrl[i].split('/').length - 2]
            var filename = _app.$data.imgUrl[i].split('/')[_app.$data.imgUrl[i].split('/').length - 1]
            // https://10.0.1.179:8080/secure/thumbnail/10703/_thumb_10703.png
            axios.get('https://218.153.187.125:8080/secure/thumbnail/' + issueId + '/_thumb_' + filename.split('.')[0] + '.png',  {headers: {'Accept':'application/json', 'Authorization':'Basic czA2MjdqczpwbHVnaW5zdzEwMDQ='}}, {httpsAgent : { rejectUnauthorized : false}})
            .then(function(response) {
            })
          }
        }*/
      }) 
  },
  methods: {
    textLengthOverCut(text) {
      if (text.length > 100){
        text = text.substr(0, 100) +'...';
      }
      return text
    }
  }
})


</script>