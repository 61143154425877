<template>
   <div v-if="this.isMobile !== true" class="desktop">
    <b-navbar class="bg-dark" toggleable="lg" type="dark" variant="info" fixed="bottom">
      <b-navbar-brand><router-link to="/" :style="{color : 'white'}">PluginSoft</router-link></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-b-modal.personal_info>개인정보처리방침</b-nav-item>

          <b-modal id="personal_info" size="lg" title="개인정보 처리 방침">
            <div>
              <h6>Pluginsoft는 더욱 발전된 서비스를 제공하기 위하여 고객의 개인정보를 수집하고 있습니다.</h6><br>
              Pluginsoft가 취급하는 모든 개인정보는 관련 법령에 근거하여 수집 및 보유하고 있습니다.<br>
              개인정보보호법은 개인정보 취급에 대한 일반적 규범을 제시하고 있으며, Pluginsoft는 이 법령에 따리 수집, 보유 및 처리하는 개인정보를
              서비스 제공을 위하여 적법하고 정정하게 취급하겠습니다.<br>
            </div>
          </b-modal>
          <b-nav-item disabled>서울 강남구 강남대로94길 28, 604호</b-nav-item>
          <b-nav-item disabled>E-mail) support@pluginsoft.co.kr</b-nav-item>
          <b-nav-item disabled>Tel) 070-8952-1255</b-nav-item>
        </b-navbar-nav>


        <b-navbar-nav class="ml-auto">

          <b-nav-item disabled>COPYRIGHT (C) PLUGINSOFT. ALL RIGHT RESERVED. </b-nav-item>
        
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
  <div v-else class="mobile">
    <b-navbar class="bg-dark" toggleable="lg" type="dark" variant="info" fixed="bottom">
      <b-navbar-brand>
        <router-link to="/" :style="{color : 'white'}">PluginSoft</router-link>
      </b-navbar-brand>
      <b-navbar-nav class="text">
        <b-nav-item disabled>E-mail) support@pluginsoft.co.kr</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div> 
</template>

<script scoped>

export default ({
     data() {
        return {
            isMobile: false,
            isTablet: false,
            isDesktop: false
        }
   },
    mounted() {
      // 기기의 크기에 따라 브라우저 윈도우의 너비를 기준으로 판단할 수 있습니다.
      this.isMobile = window.innerWidth < 768;
      this.isTablet = window.innerWidth >= 768 && window.innerWidth < 992;
      this.isDesktop = window.innerWidth >= 992;
    },
    computed: {
      changeClass() {
        // 기기의 크기에 따라 클래스를 동적으로 할당합니다.
        return {
          "mobile": this.isMobile,
          "tablet": this.isTablet,
          "desktop": this.isDesktop
        };
      }
    }
})
</script>

<style scoped>


.desktop .bg-dark {
  height: 7vh;
}

.mobile .bg-dark {
  height: 7vh;
}

.mobile .bg-dark .text {
  font-size: 15px;
}

</style>