// export default {
//   apiKey: "AIzaSyC8gB8X_W2ruyBaQPvs9OhjaoVXPXf7BJ8",
//   authDomain: "pluginsoft-59862.firebaseapp.com",
//   projectId: "pluginsoft-59862",
//   storageBucket: "pluginsoft-59862.appspot.com",
//   messagingSenderId: "754609889902",
//   appId: "1:754609889902:web:6b7dc7447828091f7fbc7f",
//   measurementId: "G-GXG6KN7W68"
// }

export default {
  apiKey: "AIzaSyAwJr1c6ZpxmmzIJGg7sAlrfr-c-qPYKJU",
  authDomain: "pluginsofthomepage.firebaseapp.com",
  projectId: "pluginsofthomepage",
  storageBucket: "pluginsofthomepage.appspot.com",
  messagingSenderId: "940591697074",
  appId: "1:940591697074:web:e85ae75567828481418dab",
  measurementId: "G-L86DNZSC6Q"
}
