import Vue from 'vue'
import App from './App.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import { initializeApp } from 'firebase/app'
import firebaseConfig from '../firebaseConfig'

const fapp = initializeApp(firebaseConfig)

Vue.prototype.$firebase = fapp

//Vue router
import router from './router/index.js'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false

new Vue({
  created() {
    AOS.init();
  },
  render: h => h(App),
  router: router,
}).$mount('#app')