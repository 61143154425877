<template>
  <div>
  <!-- fixed="top" NavBar 고정 시 문의 버튼 가려짐 -->
    <b-navbar class="bg-white" :style="{color : 'white'}" fixed="top">
      <!-- <router-link to="/"><img src="../assets/pluginsw-logo.png" class="logo"></router-link> -->
      <img src="../assets/pluginsw-logo.png" class="logo" @click="menuClick(0)">

    <div class="ml-auto">
      <div @mouseover="onOverMenu1" @mouseleave="onLeaveMenu1">
        <b-dropdown variant="white" id="dropdown-1" text="소개" ref="dropdown1" class="m-md-2">
          <!-- <b-dropdown-item to="/Intro" :style="{color : 'black'}">회사소개</b-dropdown-item>
          <b-dropdown-item to="/History" :style="{color : 'black'}">연혁</b-dropdown-item> -->
          <b-dropdown-item :style="{color : 'black'}" @click="menuClick(1)">회사소개</b-dropdown-item>
          <b-dropdown-item :style="{color : 'black'}" @click="menuClick(2)">연혁</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  
    <div>
      <div @mouseover="onOverMenu2" @mouseleave="onLeaveMenu2">
        <b-dropdown variant="white" id="dropdown-2" text="사업" ref="dropdown2" class="m-md-2">
          <!-- <b-dropdown-item to="/BussinessIntro" :style="{color : 'black'}">사업 개요</b-dropdown-item>
          <b-dropdown-item to="/BussinessTarget" :style="{color : 'black'}">사업 분야</b-dropdown-item>
          <b-dropdown-item to="/BussinessPartner" :style="{color : 'black'}">파트너</b-dropdown-item> -->
          <!-- <b-dropdown-item :style="{color : 'black'}" @click="menuClick(3)">사업 개요</b-dropdown-item>
          <b-dropdown-item :style="{color : 'black'}" @click="menuClick(4)">사업 분야</b-dropdown-item> -->
          <b-dropdown-item :style="{color : 'black'}" @click="menuClick(3)">주력 솔루션</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  
    <div>
      <div @mouseover="onOverMenu3" @mouseleave="onLeaveMenu3">
        <b-dropdown variant="white" id="dropdown-3" text="공지사항" ref="dropdown3" class="m-md-2">
          <!-- <b-dropdown-item to="/Recruit" :style="{color : 'black'}">모집공고</b-dropdown-item>
          <b-dropdown-item to="/Bullettin" :style="{color : 'black'}">게시판</b-dropdown-item> -->
          <!-- <b-dropdown-item :style="{color : 'black'}" @click="menuClick(6)">모집공고</b-dropdown-item> -->
          <b-dropdown-item :style="{color : 'black'}" @click="menuClick(4)">게시판</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  
  <!--
    <div>
      <div @mouseover="onOverMenu4" @mouseleave="onLeaveMenu4">
        <b-dropdown variant="white" id="dropdown-4" text="자료실" ref="dropdown4" class="m-md-2">
          <b-dropdown-item href="https://www.naver.com" target='_blank'>Jira 기술자료&nbsp;&nbsp;<img src="../assets/new-window.png" width="12px" height="12px"></b-dropdown-item>
          <b-dropdown-item href="https://www.naver.com" target='_blank'>Confluence 기술자료&nbsp;&nbsp;<img src="../assets/new-window.png" width="12px" height="12px"></b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
-->
      <b-navbar-nav class="ml-auto">
        <!-- <PluginsoftInquiry></PluginsoftInquiry> -->
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script scoped>
// import PluginsoftInquiry from "./PluginsoftInquiry.vue";

export default({
  components: {
    // PluginsoftInquiry,
  },
  
  setup() {
    
  },

  data: () => ({
    dashBoardList : ["System Dashboard", 'Vue.js 기술 자료', 'Jira 기술 자료'],
  }),

  methods: {
    onOverMenu1() {
      this.$refs.dropdown1.visible = true;
    },
    onOverMenu2() {
      this.$refs.dropdown2.visible = true;
    },
    onOverMenu3() {
      this.$refs.dropdown3.visible = true;
    },
    onOverMenu4() {
      this.$refs.dropdown4.visible = true;
    },
    onLeaveMenu1() {
      this.$refs.dropdown1.visible = false;
    },
    onLeaveMenu2() {
      this.$refs.dropdown2.visible = false;
    },
    onLeaveMenu3() {
      this.$refs.dropdown3.visible = false;
    },
    onLeaveMenu4() {
      this.$refs.dropdown4.visible = false;
    },
    onClickedIntroduction() {
      this.$emit('/about')
    },
    menuClick (menuNumber) {
      console.log(menuNumber)
      this.$emit('menuClick', menuNumber)
    }
  }
})
</script>

<style scoped>
.logo{
  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.bg-white {
  height: 10vh;
}
</style>