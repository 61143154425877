<template>
    <body>
        <div class="background" style="display:flex; justify-content: center; align-items: center;">
            <div v-if="this.isMobile !== true" class="section1">
                <h4>주력 솔루션</h4>
                <div class="block">
                    <a href="https://www.atlassian.com/ko" target="_blank" class="quick">
                        <img style="width:200px; height:200px;" src="../assets/atlassian.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a>
                    <a href="https://about.gitlab.com/" target="_blank" class="quick last">
                        <img style="width:200px; height:200px;" src="../assets/gitlab-logo.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a>
                    <!-- <a href="https://flow.team/kr/index?utm_source=google&utm_medium=cpc&utm_campaign=sa_pc&utm_content=G0_C1&utm_trem=%ED%94%8C%EB%A1%9C%EC%9A%B0&gclid=Cj0KCQiA14WdBhD8ARIsANao07jR2Z7vybggFVUA1u9Gt7R6Bp2ctQf035uN1ocqnsLvIbXjO5sw_bYaAvPLEALw_wcB" target="_blank" class="quick">
                        <img style="width:200px; height:200px;" src="../assets/flow-logo.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a> -->
                    <a href="https://www.salesforce.com/kr/?d=7013y000002hJ3d&DCMP=Google-KR&gclid=Cj0KCQiA14WdBhD8ARIsANao07i2sWZ-_tQhC-QbYQN4MZ8m2qoj_r0p5_RZ_hcr77vBv5fpFCjJJtAaAhT9EALw_wcB" target="_blank" class="quick">
                        <img style="width:200px; height:200px;" src="../assets/salesforce-logo.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a>
                    <a href="https://aws.amazon.com/ko/free/?trk=fa2d6ba3-df80-4d24-a453-bf30ad163af9&sc_channel=ps&s_kwcid=AL!4422!3!563761819834!e!!g!!aws&ef_id=Cj0KCQiAtICdBhCLARIsALUBFcG1LMP9JH8l1uuGvUP99th4Vv2fl9gekCW4ZlCZGn9ZZF6hTKX49lUaAlMsEALw_wcB:G:s&s_kwcid=AL!4422!3!563761819834!e!!g!!aws&all-free-tier.sort-by=item.additionalFields.SortRank&all-free-tier.sort-order=asc&awsf.Free%20Tier%20Types=*all&awsf.Free%20Tier%20Categories=*all" target="_blank" class="quick">
                        <img style="width:200px; height:200px;" src="../assets/AWS-logo.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a>
                </div>
            </div>
            <div v-else class="section2">
                <h4>주력 솔루션</h4>
                <div class="block">
                    <a href="https://www.atlassian.com/ko" target="_blank" class="quick">
                        <img style="width:90px; height:90px;" src="../assets/atlassian.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a>
                    <a href="https://about.gitlab.com/" target="_blank" class="quick last">
                        <img style="width:90px; height:90px;" src="../assets/gitlab-logo.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a>
                    <!-- <a href="https://flow.team/kr/index?utm_source=google&utm_medium=cpc&utm_campaign=sa_pc&utm_content=G0_C1&utm_trem=%ED%94%8C%EB%A1%9C%EC%9A%B0&gclid=Cj0KCQiA14WdBhD8ARIsANao07jR2Z7vybggFVUA1u9Gt7R6Bp2ctQf035uN1ocqnsLvIbXjO5sw_bYaAvPLEALw_wcB" target="_blank" class="quick">
                        <img style="width:90px; height:90px;" src="../assets/flow-logo.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a> -->
                    <a href="https://www.salesforce.com/kr/?d=7013y000002hJ3d&DCMP=Google-KR&gclid=Cj0KCQiA14WdBhD8ARIsANao07i2sWZ-_tQhC-QbYQN4MZ8m2qoj_r0p5_RZ_hcr77vBv5fpFCjJJtAaAhT9EALw_wcB" target="_blank" class="quick">
                        <img style="width:90px; height:90px;" src="../assets/salesforce-logo.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a>
                    <a href="https://aws.amazon.com/ko/free/?trk=fa2d6ba3-df80-4d24-a453-bf30ad163af9&sc_channel=ps&s_kwcid=AL!4422!3!563761819834!e!!g!!aws&ef_id=Cj0KCQiAtICdBhCLARIsALUBFcG1LMP9JH8l1uuGvUP99th4Vv2fl9gekCW4ZlCZGn9ZZF6hTKX49lUaAlMsEALw_wcB:G:s&s_kwcid=AL!4422!3!563761819834!e!!g!!aws&all-free-tier.sort-by=item.additionalFields.SortRank&all-free-tier.sort-order=asc&awsf.Free%20Tier%20Types=*all&awsf.Free%20Tier%20Categories=*all" target="_blank" class="quick">
                        <img style="width:90px; height:90px;" src="../assets/AWS-logo.png" alt="위의 이미지를 누르면 연결됩니다.">
                    </a>
                </div>
            </div>
        </div>
    </body>
</template>

<script scoped>
// import axios from 'axios'
// var baseUrl = 'https://218.153.187.125:8080/rest/api/2'
//var baseUrl = 'https://10.0.1.179:8080/rest/api/2'

export default ({
     data() {
        return {
            isMobile: false,
            isTablet: false,
            isDesktop: false
        }
   },
    mounted() {
      // 기기의 크기에 따라 브라우저 윈도우의 너비를 기준으로 판단할 수 있습니다.
      this.isMobile = window.innerWidth < 768;
      this.isTablet = window.innerWidth >= 768 && window.innerWidth < 992;
      this.isDesktop = window.innerWidth >= 992;
    },
    computed: {
      changeClass() {
        // 기기의 크기에 따라 클래스를 동적으로 할당합니다.
        return {
          "mobile": this.isMobile,
          "tablet": this.isTablet,
          "desktop": this.isDesktop
        };
      }
    },   
    created() {
        // var _app = this;
        // axios.get( baseUrl + '/issue/HOME-10', {headers: {'Accept':'application/json', 'Authorization':'Basic czA2MjdqczpwbHVnaW5zdzEwMDQ='}}, {httpsAgent : { rejectUnauthorized : false}})
        // .then(function (response)  {
        //         _app.$data.PluginsoftBussinessPartner = response.data.fields.description.toString()
        // })
    },
})
</script>

<style scoped>
.background {
    height: 100vh;
    overflow: hidden;
    margin: 0;
    background-image: url("../assets/letterpaper.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

body {
    width:100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    align-items: center;
    line-height: 50px;
    vertical-align: middle;
}

.section1 {
	height: 600px;
	background-size: cover;
}

.section1 h4{
	display: block;
	padding: 25px 0 0px;
	color: #333;
	font-size: 2em;
	text-align: center;
}

.section1 p{
	color: #333;
	text-align: center;
	padding-bottom: 0px;
}

.section1 .block{
	height: 1140px;
	margin: 0 auto;
	overflow: hidden;
}

.section1 .block .quick{
	float: left;
	width: 200px;
	height: 200px;
	text-align: left;
	margin: 2% 15% 6% 10%;
	border-radius: 20px;
	box-sizing: border-box;
}

.section1 .block .last{
	margin-right: 0px;
}

.section1 .quick h4{
	margin: 30px 30px 30px 30px;
	font-size: 18px;
}

.section1 .quick p{
	margin: 0 30px;
	font-size: 15px;
	color: #333;
	text-align: left;
	letter-spacing: -2px;
}

.section2 {
	height: 300px;
	background-size: cover;
}

.section2 h4{
	display: block;
	padding: 25px 0 0px;
	color: #333;
	font-size: 2em;
	text-align: center;
}

.section2 p{
	color: #333;
	text-align: center;
	padding-bottom: 0px;
}

.section2 .block{
	height: 1140px;
	margin: 0 auto;
	overflow: hidden;
}

.section2 .block .quick{
	float: left;
	width: 100px;
	height: 100px;
	text-align: left;
	margin: 1% -7% 3% 18%;
	border-radius: 20px;
	box-sizing: border-box;
}

.section2 .block .last{
	margin-right: 0px;
}

.section2 .quick h4{
	margin: 30px 30px 30px 30px;
	font-size: 18px;
}

.section2 .quick p{
	margin: 0 30px;
	font-size: 15px;
	color: #333;
	text-align: left;
	letter-spacing: -2px;
}
</style>