<template>
  <div id="app">
    
    <NavBar v-if="showNavbar" @menuClick="menuClick"></NavBar>

    <!-- <v-main>
      <router-view></router-view>
    </v-main> -->

    <transition name="fade">
      <BVCarousel class="message" key="down" v-if="visibleNumber==0" data-aos="zoom-in-down">

      </BVCarousel>

      <PluginsoftIntro class="message" key="up" v-if="visibleNumber==1" data-aos="zoom-in-down">

      </PluginsoftIntro>

      <PluginsoftHistory class="message" key="up" v-if="visibleNumber==2" data-aos="zoom-in-down">

      </PluginsoftHistory>

      <!-- <BussinessIntro class="message" key="up" v-if="visibleNumber==3" data-aos="zoom-in-down">

      </BussinessIntro>

      <BussinessTarget class="message" key="up" v-if="visibleNumber==4" data-aos="zoom-in-down">

      </BussinessTarget> -->
      
      <BussinessPartner class="message" key="up" v-if="visibleNumber==3" data-aos="zoom-in-down">

      </BussinessPartner>

      <!-- <RecruitBoard class="message" key="up" v-if="visibleNumber==6" data-aos="zoom-in-down">

      </RecruitBoard> -->

      <BulletinBoard class="message" key="up" v-if="visibleNumber==4" data-aos="zoom-in-down">

      </BulletinBoard>
      
      
      <!-- <div class="message" key="down" v-if="visibleNumber==0">
        <div class="text">
          Scroll Down to Hide Navbar
        </div>
        <svg class="arrow" viewBox="0 0 24 24">
          <path fill="#000000" d="M9,4H15V12H19.84L12,19.84L4.16,12H9V4Z" />
        </svg>
      </div>
      <div class="message" key="up" v-if="visibleNumber!=0">
        <div class="text">
          Scroll Up to Show Navbar
        </div>
        <svg class="arrow" viewBox="0 0 24 24">
          <path fill="#000000" d="M14,20H10V11L6.5,14.5L4.08,12.08L12,4.16L19.92,12.08L17.5,14.5L14,11V20Z" />
        </svg>
      </div> -->
    </transition>



    
    <NavFooter class="Footer"></NavFooter>

  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import NavFooter from "./components/NavFooter.vue"
import BVCarousel from './components/BVCarousel.vue'
import PluginsoftIntro from './components/PluginsoftIntro.vue'
import PluginsoftHistory from './components/PluginsoftHistory.vue'
// import BussinessIntro from './components/PluginsoftBussinessIntro.vue'
// import BussinessTarget from './components/PluginsoftBussinessTarget.vue'
import BussinessPartner from './components/PluginsoftBussinessPartner.vue'
// import RecruitBoard from './components/RecruitBoard.vue'
import BulletinBoard from './components/BulletinBoard.vue'

const OFFSET = 80

export default {
  name: 'App',
  components: {
    NavBar,
    NavFooter,
    BVCarousel,
    PluginsoftIntro,
    PluginsoftHistory,
    // BussinessIntro,
    // BussinessTarget,
    BussinessPartner,
    // RecruitBoard,
    BulletinBoard
  },
  watch: {
    // menuClick(newVal) {
    //   console.log(12312313)
    //   this.visibleNumber = newVal
    // }
    // visibleNumber(newVal, oldVal) {
      
    //   // if (newVal > oldVal) {
    //   //   console.log(123)
    //   //   window.scrollTo(0, 0)
    //   // }
    // }
  },
  beforeDestroy () {
    window.removeEventListener('wheel', this.handleScroll)
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('wheel', this.handleScroll)
    // const viewportMeta = document.createElement('meta')
    // viewportMeta.name = 'viewport'
    // viewportMeta.content = 'width=device-width, initial-scale=1'
    // document.head.appendChild(viewportMeta)
 },
 data() {
  return {
    visibleNumber : 0,
    visibleMain: true,
    showNavbar: true,
    lastScrollPosition: 0,
    scrollValue: 0,
    scrollPostion : 0,
    // menuClick : 0
    }
 },
 methods: {
    menuClick (mc) {
      console.log(1213123)
      this.visibleNumber = mc
    },
    handleScroll (e) {
      // this.scrollPostion = e.target.scrollTop 
      

      console.log('scrollPostion', e.deltaY )

      if (e.deltaY > 0) {
        if (this.visibleNumber >= 4) return
        this.visibleNumber++
      }
      else {
        if (this.visibleNumber == 0) return
        this.visibleNumber--
      }

      window.scrollTo(0, 0)

      // if(this.scrollPosition > 100){
      //   console.log("UP")
      // } else {
      //   console.log("DOWN")
      // }
    },
    onScroll () {
      // if (window.pageYOffset < 0) {
      //   console.log(123)
      //   return
      // }
      // if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
      //   console.log('lastScrollPosition', this.lastScrollPosition)
      //   console.log('pageYOffset', window.pageYOffset)
      //   console.log(456)
      //   return
      // }
      
      console.log('this.lastScrollPosition', this.lastScrollPosition)
      console.log(this.visibleNumber)
      // this.showNavbar = window.pageYOffset < this.lastScrollPosition

      if (this.lastScrollPosition > window.pageYOffset && Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        if (this.visibleNumber != 0) {
          this.visibleNumber--
        }
      }
      else if (this.lastScrollPosition < window.pageYOffset && Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        if (this.visibleNumber != 2) {
          this.visibleNumber++
        }
      }

      console.log(this.visibleNumber)

      
      this.lastScrollPosition = window.pageYOffset

      // window.scrollTo(0, 0)
    }
  }
}

</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */


</style>
