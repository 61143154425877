<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide>
        <template #img>
          <img
            :class="imageClass"
            src="../assets/Explore-jira-plugins.jpg"
            alt="image slot"
          >
        </template>
        <h1 style="color:black;">Atlassian Plugins</h1>
        <h3 style="color:black;">Atlassian Plugins</h3>
      </b-carousel-slide>

      <!-- Slides with custom text -->
     <b-carousel-slide>
        <template #img>
          <img
            :class="imageClass"
            src="../assets/Atlassian-Devops.jpg"
            alt="image slot"
          >
        </template>
        <h1 style="color:black;">DevOps</h1>
        <h3 style="color:black;">Agile</h3>
      </b-carousel-slide>

      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <b-carousel-slide>
        <template #img>
          <img
            :class="imageClass"
            src="../assets/gitlab.jpg"
            alt="image slot"
          >
        </template>
        <h1>GitLab</h1>
        <h3>GitLab Partner</h3>
      </b-carousel-slide>

      <!-- Slide with blank fluid image to maintain slide aspect ratio -->
      <b-carousel-slide>
        <template #img>
          <img
            :class="imageClass"
            src="../assets/artificial-intelligence.jpg"
            alt="image slot"
          >
        </template>
        <h1 style="color:white;">A.I.</h1>
        <h3 style="color:white;">Artificial Intelligence</h3>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script scoped>
  export default {
    data() {
      return {
        slide: 0,
        sliding: null,
        isMobile: false,
        isTablet: false,
        isDesktop: false
      }
    },
    mounted() {
      // 기기의 크기에 따라 브라우저 윈도우의 너비를 기준으로 판단할 수 있습니다.
      this.isMobile = window.innerWidth < 768;
      this.isTablet = window.innerWidth >= 768 && window.innerWidth < 992;
      this.isDesktop = window.innerWidth >= 992;
    },
    computed: {
      imageClass() {
        // 기기의 크기에 따라 클래스를 동적으로 할당합니다.
        return {
          "img-fluid": true, // 기본적으로 이미지는 반응형으로 유지됩니다.
          "img-mobile": this.isMobile,
          "img-tablet": this.isTablet,
          "img-desktop": this.isDesktop
        };
      }
    },
    methods: {
      onSlideStart(slide) {
        slide;
        this.sliding = true
      },
      onSlideEnd(slide) {
        slide;
        this.sliding = false
      }
    }
  }
</script>

<style scoped>
.img-mobile {
  width: 1800px;
  height: 480px; /* 모바일 화면에서의 이미지 크기 */
}

.img-tablet {
  width: 1800px;
  height: 650px; /* 태블릿 화면에서의 이미지 크기 */
}

.img-desktop {
  width: 1800px;
  height: 730px; /* 데스크탑 화면에서의 이미지 크기 */
}
</style>