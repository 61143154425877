<template>
    <div>
        <BVCarousel></BVCarousel>
        <BVCardDeckGroup></BVCardDeckGroup>
    </div>
</template>

<script scoped>
import BVCarousel from './BVCarousel.vue'
import BVCardDeckGroup from './BVCardDeckGroup.vue'

export default ({
    components: {
        BVCarousel,
        BVCardDeckGroup,
    }
})
</script>