import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/MainContents.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Intro',
        name: 'Intro',
        component: () => import('../components/PluginsoftIntro.vue')
    },
    {
        path: '/History',
        name: 'History',
        component: () => import('../components/PluginsoftHistory.vue')
    },
    {
        path: '/BussinessIntro',
        name: 'BussinessIntro',
        component: () => import('../components/PluginsoftBussinessIntro.vue')
    },
    {
        path: '/BussinessTarget',
        name: 'BussinessTarget',
        component: () => import('../components/PluginsoftBussinessTarget.vue')
    },
    {
        path: '/BussinessPartner',
        name: 'BussinessPartner',
        component: () => import('../components/PluginsoftBussinessPartner.vue')
    },
    {
        path: '/Bullettin',
        name: 'Bullettin',
        component: () => import('../components/BulletinBoard.vue')
    },
    {
        path: "/Bullettin/detail/:contentId",
        name: "BullettinDetail",
        component: () => import('../components/BoardDetail.vue')
    },
    {
        path: '/Recruit',
        name: 'Recruit',
        component: () => import('../components/RecruitBoard.vue')
    },
    {
        path: '/RecruiDetail/:contentId',
        name: 'RecruitDetail',
        component: () => import('../components/RecruitDetail.vue')
    },
    
]


const router = new VueRouter({
    mode: 'history',
    routes
    // scrollBehavior (to, from, savedPosition) {
    //     return { x: 0, y: 0 }
    // }
  })
  
export default router