<template>
    <body>
        <!-- <NaverMap></NaverMap> -->
        <div id="wrap" class="background" style="display:flex; justify-content: center; align-items: center;">
            <div v-if="this.isMobile !== true" class="section1">
                <h2>회사소개</h2>
                <hr class="hidden" />
                <div id="contents">
                    <div id="campaign" style="line-height: 1.7em;">
                        <p class="caption">
                            플러긴소프트는 SaaS 기반 AI 솔루션을 개발하는 기업입니다.
                            <br>
                            모든 Atlassian 제품(JIRA, Confluence, Bitbucket 등)에 대한
                            <br>
                            plugin 개발 및 컨설팅서비스와 기술지원을 수행하는 기업입니다.
                            <br>
                            Gitlab 파트너사로써 컨설팅서비스 및 구축지원을 수행하는 기업입니다.
                        </p>
                    </div>
                </div>
                <hr class="hidden" />
                <div class="block">
                    <img src="../assets/pluginsoft-place-2.png" style="width:400px; height:400px;">
                </div>
            </div>
            <div v-else class="section2">
                <h3>회사소개</h3>
                <hr class="hidden" />
                <div id="contents">
                    <div id="campaign" style="line-height: 1.2em;">
                        <p class="caption2">
                            플러긴소프트는 SaaS 기반 AI 솔루션을 개발하는 기업입니다.
                            <br>
                            모든 Atlassian 제품(JIRA, Confluence, Bitbucket 등)에 대한
                            <br>
                            plugin 개발 및 컨설팅서비스와 기술지원을 수행하는 기업입니다.
                            <br>
                            Gitlab 파트너사로써 컨설팅서비스 및 구축지원을 수행하는 기업입니다.
                        </p>
                    </div>
                </div>
                <hr class="hidden" />
                <div class="block">
                    <img src="../assets/pluginsoft-place-2.png" style="width:180px; height:180px;">
                </div>
            </div>
        </div>
    </body>
</template>


<script scoped>
// import NaverMap from '../components/NaverMap.vue'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

export default ({
    data() {
        return {
            PluginsoftIntro : '',
            isMobile: false,
            isTablet: false,
            isDesktop: false
        }
    },
    created() {
    },
    mounted() {
      // 기기의 크기에 따라 브라우저 윈도우의 너비를 기준으로 판단할 수 있습니다.
      this.isMobile = window.innerWidth < 768;
      this.isTablet = window.innerWidth >= 768 && window.innerWidth < 992;
      this.isDesktop = window.innerWidth >= 992;
    },
    computed: {
      changeClass() {
        // 기기의 크기에 따라 클래스를 동적으로 할당합니다.
        return {
          "mobile": this.isMobile,
          "tablet": this.isTablet,
          "desktop": this.isDesktop
        };
      }
    },
    components: {
        // NaverMap
    }
})
</script>

<style scoped>
.background {
    height: 100vh;
    overflow: hidden;
    margin: 0;
    background-image: url("../assets/letterpaper.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
body {
    width:100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    align-items: center;
    line-height: 50px;
    vertical-align: middle;
}
.section1 {
	height: 650px;
	background-size: cover;
}

.section1 h2{
	display: block;
	padding: 25px 0 0px;
	color: #333;
	font-size: 2em;
	text-align: center;
}

.section1 p{
	color: #333;
	text-align: left;
	padding-bottom: 0px;
}

.section1 .block{
	margin: 0 auto;
	overflow: hidden;
	text-align: center;
}

.section1 .block .quick{
	float: left;
	width: 200px;
	height: 200px;
	text-align: left;
	margin: 4% 0% 6% 17%;
	border-radius: 20px;
	box-sizing: border-box;
}

.section1 .block .last{
	margin-right: 0px;
}

.section1 .quick h4{
	margin: 30px 30px 30px 30px;
	font-size: 18px;
}

.section1 .quick p{
	margin: 0 30px;
	font-size: 15px;
	color: #333;
	text-align: left;
	letter-spacing: -2px;
}

.section2 {
	height: 300px;
	background-size: cover;
}

.section2 h3{
	display: block;
	padding: 25px 0 0px;
	color: #333;
	font-size: 2em;
	text-align: center;
}

.section2 p{
	color: #333;
	text-align: left;
	padding-bottom: 0px;
	font-size: 9px;
}

.section2 .block{
	margin: 0 auto;
	overflow: hidden;
	text-align: center;
}

.section2 .block .quick{
	float: left;
	width: 200px;
	height: 200px;
	text-align: left;
	margin: 4% 0% 6% 17%;
	border-radius: 20px;
	box-sizing: border-box;
}

.section2 .block .last{
	margin-right: 0px;
}

.section2 .quick h4{
	margin: 30px 30px 30px 30px;
	font-size: 18px;
}

.section2 .quick p{
	margin: 0 30px;
	font-size: 15px;
	color: #333;
	text-align: left;
	letter-spacing: -2px;
}
</style>
